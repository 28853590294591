<template>
  <div class="container-cental">
    <h1 class="mt-5 mb-5">
      {{ type == "create" ? "AGREGAR" : "EDITAR" }} USUARIOS
    </h1>
    <b-form ref="formAdd" @reset="onReset">
      <div class="row p-3 mb-5">
        <!-- role -->
        <b-form-group label="Rol*" class="col-md-6 col-lg-4">
          <b-form-select
            id="role"
            v-model="form.role"
            :options="roles"
            ref="role"
            :state="roleState"
            required
            :disabled="inProcess"
          ></b-form-select>
        </b-form-group>
        <!-- identificacion -->
        <b-form-group
          label="Identificación*"
          label-for="identificacion"
          class="col-md-6 col-lg-4"
        >
          <b-form-input
            id="identificacion"
            v-model.trim="form.identificacion"
            type="text"
            ref="identificacion"
            :state="identificacionState"
            required
            :disabled="inProcess"
          ></b-form-input>
        </b-form-group>
        <!-- nombres -->
        <b-form-group
          label="Nombres*"
          label-for="nombres"
          class="col-md-6 col-lg-4"
        >
          <b-form-input
            id="nombres"
            v-model.trim="form.nombres"
            type="text"
            ref="nombres"
            :state="nombresState"
            required
            :disabled="inProcess"
          ></b-form-input>
        </b-form-group>
        <!-- apellidos -->
        <b-form-group
          label="Apellidos*"
          label-for="apellidos"
          class="col-md-6 col-lg-4"
        >
          <b-form-input
            id="apellidos"
            v-model.trim="form.apellidos"
            type="text"
            ref="apellidos"
            :state="apellidosState"
            required
            :disabled="inProcess"
          ></b-form-input>
        </b-form-group>
        <!-- email -->
        <b-form-group
          label="Email*"
          label-for="email"
          class="col-md-6 col-lg-4"
        >
          <b-form-input
            id="email"
            v-model.trim="form.email"
            type="email"
            ref="email"
            :state="emailState"
            required
            :disabled="inProcess || type == 'edit'"
          ></b-form-input>
        </b-form-group>
        <!-- telefono -->
        <b-form-group
          label="Teléfono*"
          label-for="telefono"
          class="col-md-6 col-lg-4"
        >
          <b-form-input
            id="telefono"
            v-model.trim="form.telefono"
            type="text"
            ref="telefono"
            :state="telefonoState"
            required
            :disabled="inProcess"
          ></b-form-input>
        </b-form-group>
        <!-- nacimiento	 -->
        <b-form-group
          label="Nacimiento*"
          label-for="nacimiento"
          class="col-md-6 col-lg-4"
        >
          <b-form-input
            id="nacimiento"
            v-model.trim="form.nacimiento"
            type="date"
            ref="nacimiento"
            :state="nacimientoState"
            :max="maxDateBirthday"
            required
            :disabled="inProcess"
          ></b-form-input>
        </b-form-group>
      </div>
      <b-button variant="primary" @click="handleSubmit">Aceptar</b-button>
      <b-button
        variant="danger"
        class="ml-3"
        @click="$router.push({ name: 'UsersList' })"
        >Cancelar</b-button
      >
    </b-form>
  </div>
</template>

<script>
import UsersService from "@/services/users.service";
import EventBus from "@/services/eventBus.js";
export default {
  name: "UserProcessAdmin",
  data() {
    return {
      usersService: null,
      roles: [
        "admin",
        "superadmin",
        "public",
        "domicilio" /* , "produccion" */,
      ],
      form: {
        role: null,
        identificacion: null,
        nombres: null,
        apellidos: null,
        email: null,
        telefono: null,
        nacimiento: null,
      },
      roleState: null,
      identificacionState: null,
      nombresState: null,
      apellidosState: null,
      emailState: null,
      telefonoState: null,
      nacimientoState: null,
      type: "create",
      maxDateBirthday: this.$moment().format("YYYY-MM-DD"),
      inProcess: false,
    };
  },
  created() {
    this.usersService = new UsersService();
    this.$route.params.id ? (this.type = "edit") : (this.type = "create");
  },
  mounted() {
    if (this.type == "edit") {
      this.recoveryDataRegister();
    }
  },
  methods: {
    handleSubmit() {
      if (!this.validation()) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: "Debes completar los campos obligatorios",
        });
        return false;
      }
      if (this.type === "create") {
        this.createRegister();
      } else {
        this.editRegister();
      }
    },
    recoveryDataRegister() {
      this.usersService
        .getById(this.$route.params.id)
        .then((result) => {
          this.form = { ...result.data.data };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createRegister() {
      this.inProcess = true;
      this.usersService
        .createUserAdmin(this.form)
        .then((result) => {
          console.log("result", result);
          if (result.data.status == 200) {
            this.form = {
              role: null,
              identificacion: null,
              nombres: null,
              apellidos: null,
              email: null,
              telefono: null,
              nacimiento: null,
            };
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Registro creado satisfactoriamente",
            });
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: result.data.errores.join(" - "),
            });
          }
          this.inProcess = false;
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al guardar el registro",
          });
          this.inProcess = false;
        });
    },
    editRegister() {
      this.inProcess = true;
      this.usersService
        .editUserAdmin(this.form)
        .then((result) => {
          if (result.data.status == 200) {
            this.form = {
              role: null,
              identificacion: null,
              nombres: null,
              apellidos: null,
              email: null,
              telefono: null,
              nacimiento: null,
            };
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Registro editado satisfactoriamente",
            });
            this.$router.push({ name: "UsersList" });
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: result.data.errores.join(" - "),
            });
          }
          this.inProcess = false;
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al editar el registro",
          });
          this.inProcess = false;
        });
    },
    onReset() {
      this.form = {
        role: null,
        identificacion: null,
        nombres: null,
        apellidos: null,
        email: null,
        telefono: null,
        nacimiento: null,
      };
      this.$nextTick(() => {
        this.$refs.formAdd.reset();
      });
    },
    validation() {
      /* validando un select*/
      const validRole = this.$refs.role.required
        ? this.$refs.role.value
          ? true
          : false
        : true;
      this.roleState = validRole;
      /* identificacion*/
      const validIdentificacion = this.$refs.identificacion.checkValidity();
      this.identificacionState = validIdentificacion;
      /* nombres*/
      const validNombres = this.$refs.nombres.checkValidity();
      this.nombresState = validNombres;
      /* apellidos*/
      const validApellidos = this.$refs.apellidos.checkValidity();
      this.apellidosState = validApellidos;
      /* email*/
      const validEmail = this.$refs.email.checkValidity();
      this.emailState = validEmail;
      /* telefono*/
      const validTelefono = this.$refs.telefono.checkValidity();
      this.telefonoState = validTelefono;
      /* nacimiento*/
      const validNacimiento = this.$refs.nacimiento.checkValidity();
      this.nacimientoState = validNacimiento;
      return (
        validRole &&
        validNombres &&
        validApellidos &&
        validIdentificacion &&
        validEmail &&
        validTelefono &&
        validNacimiento
      );
    },
  },
  watch: {
    $route(to) {
      to.params.id ? (this.type = "edit") : (this.type = "create");
    },
  },
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
  padding: 0px 300px;
}
@media (max-width: 1400px) {
  .container-cental {
    padding: 0px 10px;
  }
}
</style>
